<template>
	<div>
		<TabHeader
			:active="1"
			:chooseDate="query.date"
			:dateFlag="true"
			@dateChange="dateChange"
		></TabHeader>

		<div class="smoke">
			<!-- 输入关键字查找报价 -->
			<div class="query">
				<div class="wx">
					<img class="wx_icon" src="../../assets/icon/yin.png" alt="" />
					<div ref="wxRolling" class="wx_rolling" id="wxRolling">
						{{ announcement }}
					</div>
				</div>
				<div class="find">
					<el-input
						ref="findInp"
						v-model="inputVal"
						placeholder="请输入关键字 代码"
						clearable
						prefix-icon="el-icon-search"
						@keyup.native.enter="search"
					></el-input>
					<div class="find_val" @click="search">搜索</div>
				</div>
			</div>
			<!-- tabs -->
			<div class="tabs">
				<div class="tabs_tit">
					<!-- 地区选择 -->
					<div class="tabs_name">
						<div
							class="tabs_name_item"
							:class="act == index ? 'active' : ''"
							v-for="(item, index) in tabsList"
							:key="item"
							@click="changeType(index)"
						>
							<i
								v-if="index == 0"
								class="iconfont icon-shoucang"
								style="font-size: 14px; color: #d83e26"
							></i>
							{{ item }}
						</div>
					</div>
					<!-- 品名排序 -->
					<div style="display: flex" v-if="act > 0">
						<div class="tabs_sort" @click="sorting">
							<img
								style="width: 17px; margin-right: 6px"
								src="../../assets/icon/fenlei.png"
								alt=""
							/>
							品名排序
						</div>

						<div class="tabs_screening" @click="screenFlag = !screenFlag">
							{{ screenFlag ? "收起筛选" : "展开筛选" }}
							<img
								style="width: 13px; margin-left: 4px"
								src="../../assets/icon/shaixuan.png"
								alt=""
							/>
						</div>
					</div>
				</div>

				<!-- 筛选 -->
				<div class="screening" v-if="screenFlag && act > 0">
					<div class="screening_item">
						<div class="screening_title">区域</div>
						<div class="screening_select">
							<div
								class="screening_select_item"
								:class="
									screening1 == index ? 'screening_select_item_active' : ''
								"
								v-for="(item, index) in factoryList"
								:key="index"
								@click.stop="screenChange1(index)"
							>
								{{ item }}
							</div>
						</div>
					</div>

					<div class="screening_item">
						<div class="screening_title">价格</div>
						<div class="screening_select">
							<div
								class="screening_select_item"
								:class="
									screening2 == index ? 'screening_select_item_active' : ''
								"
								v-for="(item, index) in priceList"
								:key="index"
								@click.stop="screenChange2(index)"
							>
								{{ item }}
							</div>
						</div>
					</div>

					<div class="screening_item">
						<div class="screening_title">规格</div>
						<div class="screening_select">
							<div
								class="screening_select_item"
								:class="
									screening3 == index ? 'screening_select_item_active' : ''
								"
								v-for="(item, index) in specList"
								:key="index"
								@click.stop="screenChange3(index)"
							>
								{{ item }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 大分类tabs选择 -->
			<div
				class="tabs_foods"
				ref="tabs"
				:class="{ tabs_foods_more: moreFlag }"
				v-if="!searchFlag && act > 0"
			>
				<div
					class="tabs_item"
					:class="{ active: current == index }"
					v-for="(item, index) in tabsList2"
					:key="item.Name"
					@click="handClick(index)"
				>
					<div
						v-if="index == 19"
						style="width: 100px; height: 35px; text-align: left"
					>
						{{ item.Name }}
					</div>
					<span v-else>{{ item.Name }}</span>
				</div>
				<div class="more_list" @click="moreFlag = !moreFlag">
					{{ moreFlag ? "收起" : "更多" }}
					<i
						class="el-icon-caret-bottom more_list_icon"
						:class="{ more_list_icon_active: moreFlag }"
					></i>
				</div>
			</div>

			<!-- 异动/自选报价 -->
			<div class="offer" v-if="current < 1 || act == 0">
				<div v-if="act == 0" class="offer_options" @click="addOptions">
					添加自选
				</div>

				<div class="offer_item">
					<div class="offer_item_price">
						<div
							class="offer_item_price_item"
							v-for="items in floatList"
							:key="items.Id"
							@click="goDetails(items.Title)"
						>
							<div>{{ items.Title }}1</div>
							<div class="price_float" :class="items.classColor">
								<div class="price_float_one">{{ items.value }}</div>
								<div class="price_float_two" v-if="items.classColor === 'red'">
									↑
								</div>
								<div
									class="price_float_two"
									v-else-if="items.classColor === 'green'"
								>
									↓
								</div>
								<div class="price_float_two" v-else>丨</div>
								<div class="price_float_three">
									{{ Math.abs(items.changeValue1 - 0).toFixed(1) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 报价 -->
			<div class="offer" v-if="current >= 1 && act > 0">
				<div
					:id="'scrollItem' + index"
					class="offer_item"
					v-for="(item, index) in dataList"
					:key="item.Name"
				>
					<!-- 大分类名 -->
					<div class="offer_item_title">
						<div>{{ item.Name }}</div>
						<div>微信:13417003906</div>
					</div>
					<!-- 行情报价 -->
					<div class="offer_item_price">
						<div
							class="offer_item_price_item"
							v-for="items in item.foods"
							:key="items.Id"
							@click="goDetails(items.Title)"
						>
							<div>{{ items.Title }}</div>
							<div class="price_float" :class="items.classColor">
								<div class="price_float_one">{{ items.value }}</div>
								<div class="price_float_two" v-if="items.classColor === 'red'">
									↑
								</div>
								<div
									class="price_float_two"
									v-else-if="items.classColor === 'green'"
								>
									↓
								</div>
								<div class="price_float_two" v-else>丨</div>
								<div class="price_float_three">
									{{ Math.abs(items.changeValue1 - 0).toFixed(1) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog
			title=""
			:visible.sync="sortDialog"
			width="800px"
			top="5vh"
			:show-close="false"
			:modal="true"
		>
			<div class="sort_dialog">
				<div class="title">
					<div>我的排序</div>
					<div
						style="color: rgb(153, 153, 153); cursor: pointer"
						@click="sortReset"
					>
						重置
					</div>
				</div>

				<div class="my_sort">
					<div
						class="my_sort_item"
						v-for="(item, index) in sortingList"
						:key="item"
					>
						<span style="color: #b30d0f">{{ index + 1 }}</span>
						<span>{{ item }}</span>
						<i
							class="el-icon-close"
							style="cursor: pointer"
							@click.stop="delSort(item, index)"
						></i>
					</div>

					<div class="no_my_sort" v-if="!sortingList.length">
						请选择你的排序
					</div>
				</div>

				<div class="all_sort">
					<div class="all_sort_title">点击类别名称可以快速排序</div>
					<div class="all_sort_list">
						<div
							class="all_sort_list_item"
							v-for="(item, index) in allSortList"
							:key="index"
							@click.stop="addSort(item, index)"
						>
							{{ item }}
						</div>
					</div>
				</div>

				<div class="sort_btn">
					<div class="btn" @click.stop="closeSort">取消</div>
					<div class="btn determine" @click.stop="determineSort">确定</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog
			title=""
			:visible.sync="optionalDialog"
			width="800px"
			top="5vh"
			:show-close="false"
			:modal="true"
		>
			<div class="sort_dialog">
				<optional ref="optional"></optional>

				<div class="sort_btn">
					<div class="btn" @click.stop="closeOptional">取消</div>
					<div class="btn determine" @click.stop="determineOptional">确定</div>
				</div>
			</div>
		</el-dialog>

		<AllFooter ref="AllFooter"></AllFooter>
	</div>
</template>

<script>
	import optional from "./components/optional.vue"
	export default {
		name: "smoke",
		components: {
			optional
		},
		data() {
			return {
				inputVal: "",
				announcementTimer: null,
				announcementTimer2: null,
				wxRolling: null,
				scrollWidth: null,
				timeOut: null,
				announcementRolling: null,
				announcement: "",

				loading: null,

				tabsList: [
					"自选",
					"今日行情",
					"流沙行情",
					"珠三角行情",
					"深圳A行情",
					"深圳B行情",
					"湖南行情"
				],

				query: {
					pageindex: 1,
					category1: "全部",
					date: this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}"),
					type: 0,
					isLogin: false
				},

				// 容器距离屏幕顶部高度
				heightList: [],
				act: 1,
				current: 0,
				moreFlag: false,

				tabsList2: [],
				// 报价列表
				dataAllList: [],
				dataList: [],
				searchArr: [],
				searchFlag: false,

				// 异动数据列表
				floatAllList: [],
				floatList: [],

				timer: null,
				scrollFlag: true,

				screenFlag: true,
				// 烟厂
				factoryList: [],
				priceList: [
					"全部",
					"0~100",
					"101~200",
					"201~300",
					"301~400",
					"401~500",
					"501~600",
					"601~700",
					"701~800",
					"801以上"
				],
				specList: ["全部", "标准", "中支", "细支", "短支", "雪茄", "爆珠"],

				screening1: 0,
				screening2: 0,
				screening3: 0,

				// 排序
				sortDialog: false,
				sortingList: [],
				allSortList: [],

				optionalDialog: false
			}
		},

		created() {
			this.getList()
			this.getFactory()
			this.getNoticeList()
		},

		methods: {
			// 切换地区
			async changeType(e) {
				if (e == 0) {
					await this.getMainList()
					this.act = e
					return
				}
				this.act = e
				this.query.type = e - 1
				this.getList()
			},

			// 获取公告
			async getNoticeList() {
				const { data: res } = await this.$api.offer.getNoticeList({
					type: 2
				})
				// console.log('获取公告', res[0].Content)
				this.announcement = res[0].Content

				this.$nextTick(() => {
					this.scrollWidth = this.$refs["wxRolling"].scrollWidth
					this.timeOut = Math.round(this.scrollWidth / 60)
					this.wxRolling = document.getElementById("wxRolling")
					this.wxRolling.style.transform = "translateX(0px)"

					// 定时器执行滚动公告
					this.announcementRolling = () => {
						console.log("执行", this.wxRolling.style.transform)
						if (this.wxRolling.style.transform != "translateX(0px)") {
							this.wxRolling.style.transform = "translateX(740px)"
							this.wxRolling.style.transition = "none"
						}

						this.announcementTimer2 = setTimeout(() => {
							console.log("5")
							this.wxRolling.style.transform = `translateX(${
								0 - this.scrollWidth
							}px)`
							this.wxRolling.style.transition = `transform ${this.timeOut}s linear`
						}, 1000)

						return this.announcementRolling
					}

					// 设置位移
					clearInterval(this.announcementTimer)
					clearTimeout(this.announcementTimer2)

					this.announcementTimer = setInterval(
						this.announcementRolling(),
						(this.timeOut + 1) * 1000
					)
				})
			},

			async addOptions() {
				this.optionalDialog = true

				await this.$nextTick()

				this.$refs.optional.open().then((res) => {
					console.log("res----------", res)
				})
			},

			closeOptional() {
				this.$refs.optional.close()
				this.optionalDialog = false
			},

			determineOptional() {
				this.$refs.optional.determine().then(async (res) => {
					if (res) await this.getMainList()
					this.optionalDialog = false
				})
			},

			// 获取烟厂
			async getFactory() {
				const { data } = await this.$api.offer.getFactory()
				// console.log(data)
				this.factoryList = data

				this.factoryList.unshift("全部")
			},

			// tabs点击
			async handClick(e) {
				console.log(e)
				if (this.current == e) return
				if (e < 1) {
					this.getFloatList()
					this.current = e
				} else {
					// if (this.current == 0) {
					//   await this.getList()
					// }

					this.current = e

					this.$nextTick(() => {
						this.scrollFlag = false
						const id = "scrollItem" + (e - 1)

						const offsetHeight = this.$refs.tabs.offsetHeight

						const top = document.getElementById(id).offsetTop - offsetHeight
						document.documentElement.scrollTo({
							top,
							behavior: "smooth"
						})

						setTimeout(() => {
							this.scrollFlag = true
						}, 800)
					})
				}
			},

			goDetails(title) {
				// console.log(title)
				this.$router.push({
					path: "/details",
					query: {
						title,
						type: 0
					}
				})
			},

			dateChange(date) {
				this.query.date = date
				this.getList()
			},

			// 获取报价列表
			async getList() {
				this.loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0)"
				})
				delete this.query.isChange
				this.query.date = this.$globalFun.parseTime(
					new Date(this.query.date),
					"{y}-{m}-{d}"
				)

				const { data: res } = await this.$api.index.getList(this.query)
				this.query.date = this.$globalFun.parseTime(
					new Date(res.date),
					"{y}-{m}-{d}"
				)

				res.list = res.list.filter((item) => {
					return item.foods.length
				})

				// 加跌停颜色类名
				for (const arr of res.list) {
					for (const arrs of arr.foods) {
						let classColor = ""
						let value = 0
						let changeValue1 = 0

						switch (this.query.type) {
							case 0:
								if (arrs.OfferValue - 0 > 0 && arrs.RetrieveValue - 0 > 0) {
									classColor = this.riseAndFall(
										arrs.OfferValue,
										arrs.RetrieveValue
									)
									value = arrs.OfferValue
									changeValue1 = this.$globalFun.calculate(
										arrs.OfferValue,
										arrs.RetrieveValue
									)
								} else if (
									arrs.ZSJValue - 0 > 0 &&
									arrs.ZSJRetrieveValue - 0 > 0
								) {
									classColor = this.riseAndFall(
										arrs.ZSJValue,
										arrs.ZSJRetrieveValue
									)
									value = arrs.ZSJValue
									changeValue1 = this.$globalFun.calculate(
										arrs.ZSJValue,
										arrs.ZSJRetrieveValue
									)
								} else if (
									arrs.SZValue - 0 > 0 &&
									arrs.SZRetrieveValue - 0 > 0
								) {
									classColor = this.riseAndFall(
										arrs.SZValue,
										arrs.SZRetrieveValue
									)
									value = arrs.SZValue
									changeValue1 = this.$globalFun.calculate(
										arrs.SZValue,
										arrs.SZRetrieveValue
									)
								} else if (
									arrs.SZValue2 - 0 > 0 &&
									arrs.SZRetrieveValue2 - 0 > 0
								) {
									classColor = this.riseAndFall(
										arrs.SZValue2,
										arrs.SZRetrieveValue2
									)
									value = arrs.SZValue2
									changeValue1 = this.$globalFun.calculate(
										arrs.SZValue2,
										arrs.SZRetrieveValue2
									)
								} else {
									classColor = this.riseAndFall(
										arrs.HNValue,
										arrs.HNRetrieveValue
									)
									value = arrs.HNValue
									changeValue1 = this.$globalFun.calculate(
										arrs.HNValue,
										arrs.HNRetrieveValue
									)
								}
								break

							case 1:
								classColor = this.riseAndFall(
									arrs.OfferValue,
									arrs.RetrieveValue
								)
								value = arrs.OfferValue
								changeValue1 = this.$globalFun.calculate(
									arrs.OfferValue,
									arrs.RetrieveValue
								)
								break

							case 2:
								classColor = this.riseAndFall(
									arrs.ZSJValue,
									arrs.ZSJRetrieveValue
								)
								value = arrs.ZSJValue
								changeValue1 = this.$globalFun.calculate(
									arrs.ZSJValue,
									arrs.ZSJRetrieveValue
								)
								break

							case 3:
								classColor = this.riseAndFall(
									arrs.SZValue,
									arrs.SZRetrieveValue
								)
								value = arrs.SZValue
								changeValue1 = this.$globalFun.calculate(
									arrs.SZValue,
									arrs.SZRetrieveValue
								)
								break

							case 4:
								classColor = this.riseAndFall(
									arrs.SZValue2,
									arrs.SZRetrieveValue2
								)
								value = arrs.SZValue2
								changeValue1 = this.$globalFun.calculate(
									arrs.SZValue2,
									arrs.SZRetrieveValue2
								)
								break

							default:
								classColor = this.riseAndFall(
									arrs.HNValue,
									arrs.HNRetrieveValue
								)
								value = arrs.HNValue
								changeValue1 = this.$globalFun.calculate(
									arrs.HNValue,
									arrs.HNRetrieveValue
								)
								break
						}

						arrs.classColor = classColor
						arrs.value = value
						arrs.changeValue1 = changeValue1 - 0
					}
				}
				console.log("全部", res)

				const offerData = []
				if (this.query.type == 1) {
					for (const arr of res.list) {
						const arrs = arr.foods.filter((item) => {
							return item.OfferValue !== null && item.OfferValue !== 0
						})
						offerData.push({
							Name: arr.Name,
							foods: arrs
						})
					}
					this.dataList = offerData
				} else if (this.query.type == 2) {
					for (const arr of res.list) {
						const arrs = arr.foods.filter((item) => {
							return item.ZSJValue !== null && item.ZSJValue !== 0
						})
						offerData.push({
							Name: arr.Name,
							foods: arrs
						})
					}
					this.dataList = offerData
				} else if (this.query.type == 3) {
					for (const arr of res.list) {
						const arrs = arr.foods.filter((item) => {
							return item.SZValue !== null && item.SZValue !== 0
						})
						offerData.push({
							Name: arr.Name,
							foods: arrs
						})
					}
					this.dataList = offerData
				} else if (this.query.type == 4) {
					for (const arr of res.list) {
						const arrs = arr.foods.filter((item) => {
							return item.SZValue2 !== null && item.SZValue2 !== 0
						})
						offerData.push({
							Name: arr.Name,
							foods: arrs
						})
					}
					this.dataList = offerData
				} else if (this.query.type == 5) {
					for (const arr of res.list) {
						const arrs = arr.foods.filter((item) => {
							return item.HNValue !== null && item.HNValue !== 0
						})
						offerData.push({
							Name: arr.Name,
							foods: arrs
						})
					}
					this.dataList = offerData
				} else {
					for (const arr of res.list) {
						const arrs = arr.foods.filter((item) => {
							return (
								(item.OfferValue !== null && item.OfferValue !== 0) ||
								(item.ZSJValue !== null && item.ZSJValue !== 0) ||
								(item.SZValue !== null && item.SZValue !== 0) ||
								(item.SZValue2 !== null && item.SZValue2 !== 0) ||
								(item.HNValue !== null && item.HNValue !== 0)
							)
						})
						offerData.push({
							Name: arr.Name,
							foods: arrs
						})
					}
					this.dataList = offerData
				}

				this.dataAllList = this.dataList
				this.searchArr = this.dataList

				this.current = null
				this.tabsList2 = []

				const list = []
				for (const i of res.list) {
					list.push({
						Name: i.Name
					})
				}

				this.tabsList2 = [
					{
						Name: "异动"
					},
					...list
				]

				this.current = 1
				document.documentElement.scrollTop = 0

				this.heightList = []
				this.$nextTick(() => {
					const offsetHeight = this.$refs.tabs.offsetHeight

					const list = document.querySelectorAll(".offer_item")
					list.forEach((item) => {
						this.heightList.push(item.offsetTop - offsetHeight)
					})

					setTimeout(() => {
						this.loading.close()
					}, 200)
					// console.log(this.heightList)
				})
			},

			//获取异动数据列表
			async getFloatList() {
				this.loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0)"
				})
				this.query.isChange = 1
				const form = {
					...this.query,
					isChange: 1
				}
				const { data: res } = await this.$api.index.getList(form)

				const list = []
				for (const i of res.list) {
					list.push(...i.foods)
				}

				// 加跌停颜色类名
				for (const arr of list) {
					let classColor = ""
					let value = 0
					let changeValue1 = 0

					switch (this.query.type) {
						case 0:
							if (
								arr.OfferValue - 0 > 0 &&
								arr.RetrieveValue - 0 > 0 &&
								arr.OfferValue != arr.RetrieveValue
							) {
								classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
								value = arr.OfferValue
								changeValue1 = this.$globalFun.calculate(
									arr.OfferValue,
									arr.RetrieveValue
								)
							} else if (
								arr.ZSJValue - 0 > 0 &&
								arr.ZSJRetrieveValue - 0 > 0 &&
								arr.ZSJValue != arr.ZSJRetrieveValue
							) {
								classColor = this.riseAndFall(
									arr.ZSJValue,
									arr.ZSJRetrieveValue
								)
								value = arr.ZSJValue
								changeValue1 = this.$globalFun.calculate(
									arr.ZSJValue,
									arr.ZSJRetrieveValue
								)
							} else if (
								arr.SZValue - 0 > 0 &&
								arr.SZRetrieveValue - 0 > 0 &&
								arr.SZValue != arr.SZRetrieveValue
							) {
								classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
								value = arr.SZValue
								changeValue1 = this.$globalFun.calculate(
									arr.SZValue,
									arr.SZRetrieveValue
								)
							} else if (
								arr.SZValue2 - 0 > 0 &&
								arr.SZRetrieveValue2 - 0 > 0 &&
								arr.SZValue2 != arr.SZRetrieveValue2
							) {
								classColor = this.riseAndFall(
									arr.SZValue2,
									arr.SZRetrieveValue2
								)
								value = arr.SZValue2
								changeValue1 = this.$globalFun.calculate(
									arr.SZValue2,
									arr.SZRetrieveValue2
								)
							} else {
								classColor = this.riseAndFall(arr.HNValue, arr.HNRetrieveValue)
								value = arr.HNValue
								changeValue1 = this.$globalFun.calculate(
									arr.HNValue,
									arr.HNRetrieveValue
								)
							}
							break

						case 1:
							classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
							value = arr.OfferValue
							changeValue1 = this.$globalFun.calculate(
								arr.OfferValue,
								arr.RetrieveValue
							)
							break

						case 2:
							classColor = this.riseAndFall(arr.ZSJValue, arr.ZSJRetrieveValue)
							value = arr.ZSJValue
							changeValue1 = this.$globalFun.calculate(
								arr.ZSJValue,
								arr.ZSJRetrieveValue
							)
							break

						case 3:
							classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
							value = arr.SZValue
							changeValue1 = this.$globalFun.calculate(
								arr.SZValue,
								arr.SZRetrieveValue
							)
							break

						case 4:
							classColor = this.riseAndFall(arr.SZValue2, arr.SZRetrieveValue2)
							value = arr.SZValue2
							changeValue1 = this.$globalFun.calculate(
								arr.SZValue2,
								arr.SZRetrieveValue2
							)
							break

						default:
							classColor = this.riseAndFall(arr.HNValue, arr.HNRetrieveValue)
							value = arr.HNValue
							changeValue1 = this.$globalFun.calculate(
								arr.HNValue,
								arr.HNRetrieveValue
							)
							break
					}

					arr.classColor = classColor
					arr.value = value
					arr.changeValue1 = changeValue1 - 0
				}
				let testArr = []
				switch (this.query.type) {
					case 0:
						testArr = list.filter((item) => {
							return (
								(item.OfferValue - 0 > 0 &&
									item.RetrieveValue - 0 > 0 &&
									item.OfferValue !== item.RetrieveValue) ||
								(item.ZSJValue - 0 > 0 &&
									item.ZSJRetrieveValue - 0 > 0 &&
									item.ZSJValue !== item.ZSJRetrieveValue) ||
								(item.SZValue - 0 > 0 &&
									item.SZRetrieveValue - 0 > 0 &&
									item.SZValue !== item.SZRetrieveValue) ||
								(item.SZValue2 - 0 > 0 &&
									item.SZRetrieveValue2 - 0 > 0 &&
									item.SZValue2 !== item.SZRetrieveValue2) ||
								(item.HNValue - 0 > 0 &&
									item.HNRetrieveValue - 0 > 0 &&
									item.HNValue !== item.HNRetrieveValue)
							)
						})
						break
					case 1:
						testArr = list.filter((item) => {
							return (
								item.OfferValue - 0 > 0 &&
								item.RetrieveValue - 0 > 0 &&
								item.OfferValue !== item.RetrieveValue
							)
						})
						break
					case 2:
						testArr = list.filter((item) => {
							return (
								item.ZSJValue - 0 > 0 &&
								item.ZSJRetrieveValue - 0 > 0 &&
								item.ZSJValue !== item.ZSJRetrieveValue
							)
						})
						break
					case 3:
						testArr = list.filter((item) => {
							return (
								item.SZValue - 0 > 0 &&
								item.SZRetrieveValue - 0 > 0 &&
								item.SZValue !== item.SZRetrieveValue
							)
						})
						break
					case 4:
						testArr = list.filter((item) => {
							return (
								item.SZValue2 - 0 > 0 &&
								item.SZRetrieveValue2 - 0 > 0 &&
								item.SZValue2 !== item.SZRetrieveValue2
							)
						})
						break
					case 5:
						testArr = list.filter((item) => {
							return (
								item.HNValue - 0 > 0 &&
								item.HNRetrieveValue - 0 > 0 &&
								item.HNValue !== item.HNRetrieveValue
							)
						})
						break
				}

				this.floatList = testArr
				this.floatAllList = this.floatList

				this.loading.close()

				console.log("异动testArr", testArr, this.query.type)
			},

			// 获取自选列表
			async getMainList() {
				const { data: res } = await this.$api.offer.getMainList({
					date: this.query.date,
					type: 2
				})
				// console.log(res)

				const list = res.list

				// 加跌停颜色类名
				for (const arr of list) {
					let classColor = ""
					let value = 0
					let changeValue1 = 0

					switch (this.query.type) {
						case 0:
							if (
								arr.OfferValue - 0 > 0 &&
								arr.RetrieveValue - 0 > 0 &&
								arr.OfferValue != arr.RetrieveValue
							) {
								classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
								value = arr.OfferValue
								changeValue1 = this.$globalFun.calculate(
									arr.OfferValue,
									arr.RetrieveValue
								)
							} else if (
								arr.ZSJValue - 0 > 0 &&
								arr.ZSJRetrieveValue - 0 > 0 &&
								arr.ZSJValue != arr.ZSJRetrieveValue
							) {
								classColor = this.riseAndFall(
									arr.ZSJValue,
									arr.ZSJRetrieveValue
								)
								value = arr.ZSJValue
								changeValue1 = this.$globalFun.calculate(
									arr.ZSJValue,
									arr.ZSJRetrieveValue
								)
							} else if (
								arr.SZValue - 0 > 0 &&
								arr.SZRetrieveValue - 0 > 0 &&
								arr.SZValue != arr.SZRetrieveValue
							) {
								classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
								value = arr.SZValue
								changeValue1 = this.$globalFun.calculate(
									arr.SZValue,
									arr.SZRetrieveValue
								)
							} else if (
								arr.SZValue2 - 0 > 0 &&
								arr.SZRetrieveValue2 - 0 > 0 &&
								arr.SZValue2 != arr.SZRetrieveValue2
							) {
								classColor = this.riseAndFall(
									arr.SZValue2,
									arr.SZRetrieveValue2
								)
								value = arr.SZValue2
								changeValue1 = this.$globalFun.calculate(
									arr.SZValue2,
									arr.SZRetrieveValue2
								)
							} else {
								classColor = this.riseAndFall(arr.HNValue, arr.HNRetrieveValue)
								value = arr.HNValue
								changeValue1 = this.$globalFun.calculate(
									arr.HNValue,
									arr.HNRetrieveValue
								)
							}
							break

						case 1:
							classColor = this.riseAndFall(arr.OfferValue, arr.RetrieveValue)
							value = arr.OfferValue
							changeValue1 = this.$globalFun.calculate(
								arr.OfferValue,
								arr.RetrieveValue
							)
							break

						case 2:
							classColor = this.riseAndFall(arr.ZSJValue, arr.ZSJRetrieveValue)
							value = arr.ZSJValue
							changeValue1 = this.$globalFun.calculate(
								arr.ZSJValue,
								arr.ZSJRetrieveValue
							)
							break

						case 3:
							classColor = this.riseAndFall(arr.SZValue, arr.SZRetrieveValue)
							value = arr.SZValue
							changeValue1 = this.$globalFun.calculate(
								arr.SZValue,
								arr.SZRetrieveValue
							)
							break

						case 4:
							classColor = this.riseAndFall(arr.SZValue2, arr.SZRetrieveValue2)
							value = arr.SZValue2
							changeValue1 = this.$globalFun.calculate(
								arr.SZValue2,
								arr.SZRetrieveValue2
							)
							break

						default:
							classColor = this.riseAndFall(arr.HNValue, arr.HNRetrieveValue)
							value = arr.HNValue
							changeValue1 = this.$globalFun.calculate(
								arr.HNValue,
								arr.HNRetrieveValue
							)
							break
					}

					arr.classColor = classColor
					arr.value = value
					arr.changeValue1 = changeValue1 - 0
				}

				this.floatList = list
			},

			// 搜索
			async search() {
				// console.log(this.inputVal)
				if (this.inputVal.trim() == "") {
					this.dataList = this.searchArr
					this.searchFlag = false
					return
				}

				this.inputVal = this.inputVal.toUpperCase()

				const searchArr = []
				const dataList = this.searchArr

				dataList.forEach((item) => {
					const arr = item.foods.filter((items) => {
						let key = 1
						let codeKey = 1
						let keywords = items.Title
						let codeKeywords = items.FactoryCode || ""
						for (const i of this.inputVal.trim()) {
							if (keywords.indexOf(i) < 0) {
								key = 0
								break
							} else {
								keywords = keywords.replace(i, "")
							}
						}

						for (const i of this.inputVal.trim()) {
							if (codeKeywords.indexOf(i) < 0) {
								codeKey = 0
								break
							} else {
								codeKeywords = codeKeywords.replace(i, "")
							}
						}
						return key || codeKey
					})
					// console.log(arr)
					if (arr.length > 0) {
						searchArr.push({
							Name: item.Name,
							foods: arr
						})
					}
				})

				// console.log("searchArr",searchArr)

				if (searchArr.length < 1) {
					return this.$message("查找不到，换一个试试吧")
				}

				this.dataList = searchArr
				this.searchFlag = true
			},

			// 烟厂筛选
			screenChange1(index) {
				this.screening1 = index
				if (this.current > 0) this.screenSearch()
				if (this.current == 0) this.screenLiftPriceSearch()
			},
			// 价格筛选
			screenChange2(index) {
				this.screening2 = index
				if (this.current > 0) this.screenSearch()
				if (this.current == 0) this.screenLiftPriceSearch()
			},
			// 规格筛选
			screenChange3(index) {
				this.screening3 = index
				if (this.current > 0) this.screenSearch()
				if (this.current == 0) this.screenLiftPriceSearch()
			},

			// 筛选逻辑
			screenSearch(type) {
				if (this.current < 1 && !type) return
				// console.log(this.current)

				const factory =
					this.factoryList[this.screening1] == "全部"
						? ""
						: this.factoryList[this.screening1]
				let price =
					this.priceList[this.screening2] == "全部"
						? ""
						: this.priceList[this.screening2]
				let spec =
					this.specList[this.screening3] == "全部"
						? ""
						: this.specList[this.screening3]
				let bead = ""

				if (spec == "爆珠") {
					bead = "爆珠"
					spec = ""
				}

				if (price == "801以上") {
					price = "801~99999"
				}

				let priceFlag = price ? true : false

				this.dataList = []

				this.dataAllList.forEach((item) => {
					const arr = item.foods.filter((items) => {
						if (price) {
							priceFlag =
								items.value > price.split("~")[0] - 0 &&
								items.value < price.split("~")[1] - 0
						}

						return (
							(factory ? items.Factory == factory : true) &&
							(price ? priceFlag : true) &&
							(spec ? items.Spec == spec : true) &&
							(bead ? items.Bead == bead : true)
						)
					})
					this.dataList.push({
						Name: item.Name,
						foods: arr
					})
				})

				this.dataList = this.dataList.filter((item) => {
					return item.foods.length
				})

				this.searchArr = this.dataList

				const list = []
				for (const i of this.dataList) {
					list.push({
						Name: i.Name
					})
				}

				this.tabsList2 = [
					{
						Name: "异动"
					},
					...list
				]

				// if (this.tabsList2.length < 2) {
				//   this.current = 0
				// }
			},
			// 异动筛选
			screenLiftPriceSearch() {
				if (this.current != 0) return

				const factory =
					this.factoryList[this.screening1] == "全部"
						? ""
						: this.factoryList[this.screening1]
				let price =
					this.priceList[this.screening2] == "全部"
						? ""
						: this.priceList[this.screening2]
				let spec =
					this.specList[this.screening3] == "全部"
						? ""
						: this.specList[this.screening3]
				let bead = ""

				if (spec == "爆珠") {
					bead = "爆珠"
					spec = ""
				}

				if (price == "801以上") {
					price = "801~99999"
				}

				let priceFlag = price ? true : false

				this.floatList = this.floatAllList.filter((items) => {
					if (price) {
						priceFlag =
							items.value > price.split("~")[0] - 0 &&
							items.value < price.split("~")[1] - 0
					}
					return (
						(factory ? items.Factory == factory : true) &&
						(price ? priceFlag : true) &&
						(spec ? items.Spec == spec : true) &&
						(bead ? items.Bead == bead : true)
					)
				})

				this.screenSearch(1)
			},
			// 全部
			async screenAll() {
				this.screenCurrent1 = 0
				this.screenCurrent2 = 0
				this.screenCurrent3 = 0
				this.beadFlag = false

				if (this.current > 1) this.screenSearch()
				if (this.current == 1) this.screenLiftPriceSearch()
			},

			// 品名排序
			async sorting() {
				this.allSortList = []

				const info = this.$store.state.info || ""
				const { data: res } = await this.$api.user.getInfo({
					Id: info.Id
				})
				// console.log('获取用户信息', res)
				if (res.Categorys) {
					this.sortingList = res.Categorys.split(",")
				}

				let allList = []
				for (const i of this.dataAllList) {
					if (i.Name !== "自选" && i.Name !== "异动") {
						allList.push(i.Name)
					}
				}

				for (const i of this.sortingList) {
					allList = allList.filter((item) => item.indexOf(i) < 0)
				}
				this.allSortList = allList

				this.sortDialog = true
			},

			// 添加排序
			addSort(item, index) {
				this.sortingList.push(item)
				this.allSortList.splice(index, 1)
			},

			// 删除排序
			delSort(item, index) {
				this.allSortList.unshift(item)
				this.sortingList.splice(index, 1)
			},

			// 取消排序
			closeSort() {
				this.allSortList = []
				this.sortingList = []
				this.sortDialog = false
			},

			// 重置排序
			sortReset() {
				this.sortingList = []
				this.determineSort()
			},

			// 确定排序
			determineSort() {
				const categorys = this.sortingList.join(",")

				this.$api.offer
					.addCategorys({
						categorys
					})
					.then(async () => {
						this.dataList = []
						await this.getList()
						this.sortDialog = false
						this.$message({
							message: "排序成功",
							type: "success"
						})
					})
			},

			// 判断涨跌
			riseAndFall(newV, oldV) {
				const num = newV - oldV

				if (num > 0) {
					return "red"
				} else if (num === 0) {
					return ""
				} else {
					return "green"
				}
			}
		},

		mounted() {
			window.addEventListener("scroll", () => {
				if (!this.scrollFlag || this.current < 1) return
				clearTimeout(this.timer)

				this.timer = setTimeout(() => {
					const top =
						window.pageYOffset ||
						document.documentElement.scrollTop ||
						document.body.scrollTop
					for (let i = 0; i < this.heightList.length; i++) {
						if (top < this.heightList[0]) {
							this.current = 1
							break
						} else if (top < this.heightList[i]) {
							this.current = i + 1
							break
						}
					}
				}, 500)
			})
		},

		beforeRouteLeave(to, from, next) {
			console.log("11")
			clearInterval(this.announcementTimer)
			clearTimeout(this.announcementTimer2)
			this.wxRolling.style.transform = "translateX(0px)"
			this.wxRolling.style.transition = "none"
			if (this.loading) {
				this.loading.close()
			}
			next()
		},

		activated() {
			console.log("33")
			this.$nextTick(() => {
				if (!this.timeOut) return
				clearInterval(this.announcementTimer)
				clearTimeout(this.announcementTimer2)

				this.announcementTimer = setInterval(
					this.announcementRolling(),
					(this.timeOut + 1) * 1000
				)
			})
		},

		deactivated() {
			console.log("22")
			clearInterval(this.announcementTimer)
			clearTimeout(this.announcementTimer2)
			this.wxRolling.style.transform = "translateX(0px)"
			this.wxRolling.style.transition = "none"
		}
	}
</script>

<style scoped lang="scss">
	.smoke {
		width: 1126px;
		margin: 0 auto;

		.query {
			width: 100%;
			height: 62px;
			display: flex;
			align-items: center;
			justify-content: space-around;

			.wx {
				width: 740px;
				height: 40px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2280ff;
				overflow: hidden;
				display: flex;
				align-items: center;

				.wx_icon {
					background: #fff;
					background: #fff;
					width: 18px;
					z-index: 9;
				}

				.wx_rolling {
					margin-left: 6px;
					height: 40px;
					line-height: 40px;
					white-space: nowrap;
				}
			}

			.find {
				flex: 1;
				height: 40px;
				margin-left: 35px;
				background: rgba(245, 245, 245, 0.39);
				border-radius: 10px;
				position: relative;

				.find_val {
					height: 40px;
					line-height: 40px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #d83e26;
					cursor: pointer;
					position: absolute;
					top: 0;
					right: 10px;
					z-index: 9;
				}
			}

			.date {
				width: 220px;
			}
		}

		.tabs {
			width: 100%;
			// height: 178px;
			// background: #ffffec;
			// border: 1px solid #f6731b;

			.tabs_tit {
				width: 1126px;
				height: 53px;
				background: rgba(248, 248, 248, 1);
				border-radius: 5px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.tabs_name {
					display: flex;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					line-height: 22px;
					color: #5d5d5d;

					.tabs_name_item {
						// width: 100px;
						margin-left: 26px;
						text-align: left;
						cursor: pointer;
					}
				}

				.tabs_sort {
					width: 100px;
					height: 28px;
					text-align: center;
					background: rgba(216, 62, 38, 1);
					border: 2px solid #ffffff;
					border-radius: 15px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: #ffffff;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.tabs_screening {
					height: 32px;
					line-height: 32px;
					margin-left: 12px;
					margin-right: 10px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: #000000;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.screening {
				width: 1100px;
				padding: 4px 16px 20px 10px;
				margin: 10px auto 0;
				background: rgba(248, 248, 248, 1);
				border-radius: 5px;

				.screening_item {
					padding: 3px 0 0;
					display: flex;

					.screening_title {
						width: 48px;
						height: 27px;
						margin-top: 9px;
						background: rgba(127, 127, 140, 1);
						border-radius: 17px 0px 17px 17px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 600;
						line-height: 27px;
						color: #ffffff;
						text-align: center;
					}

					.screening_select {
						margin-left: 12px;
						padding-bottom: 12px;
						flex: 1;
						display: flex;
						flex-wrap: wrap;
						border-bottom: 1px solid #eeeeee;

						.screening_select_item {
							margin-top: 9px;
							padding: 0 9px;
							height: 27px;
							font-size: 15px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 27px;
							color: #000;
							margin-left: 6px;
							cursor: pointer;
						}

						.screening_select_item_active {
							height: 27px;
							background: rgba(255, 234, 230, 1);
							border-radius: 14px;
							font-weight: 600;
							color: #d83e26;
							text-align: center;
						}
					}

					.screening_select:last-child {
						border-bottom: none;
					}
				}
			}
		}

		.tabs_foods {
			display: flex;
			flex-wrap: wrap;
			width: 1116px;
			height: 35px;
			padding: 8px 10px 8px 0;
			margin-top: 10px;
			overflow: hidden;
			background: rgba(248, 248, 248, 1);
			border-radius: 5px;
			position: sticky;
			top: 0;

			.tabs_item {
				margin-left: 14px;
				height: 35px;
				line-height: 35px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 600;
				color: #000;
				cursor: pointer;
			}

			.more_list {
				width: 68px;
				height: 29px;
				border: 2px solid #e5e5e5;
				background: #f8f8f8;
				border-radius: 15px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 600;
				color: #919191;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				position: absolute;
				top: 10px;
				right: 8px;
				z-index: 2;

				.more_list_icon {
					transition: all 0.3s;
				}

				.more_list_icon_active {
					transform: rotate(180deg);
					transform-origin: center center;
				}
			}
		}

		.tabs_foods_more {
			height: auto;
		}

		.offer {
			width: 100%;
			margin-top: 10px;
			background: url("../../assets/img/c3j4.jpg") 20%;

			.offer_options {
				width: 90px;
				height: 30px;

				border-radius: 20px;
				background-color: #9b150a;

				color: #fff;

				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;
			}

			.offer_item {
				width: 100%;

				.offer_item_title {
					width: calc(100% - 20px);
					background: #eaeaea;
					font-size: 12px;
					font-weight: bold;
					color: rgb(152, 152, 152);
					padding: 5px 10px;
					display: flex;
					justify-content: space-between;
				}

				.offer_item_price {
					width: 100%;
					display: flex;
					flex-wrap: wrap;

					.offer_item_price_item {
						width: calc(25% - 17px);
						padding: 9px 8px;
						border-right: 1px solid #c8c8c8 !important;
						border-bottom: 1px solid #c8c8c8;
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 15px;
						font-weight: bold;
						color: #000;
						cursor: pointer;

						.price_float {
							width: 40%;
							display: flex;

							.price_float_one {
								width: 50%;
							}

							.price_float_two {
								margin: 0 4px;
								display: inline-block;
								width: 15px;
								text-align: center;
							}

							.price_float_three {
								flex: 1;
							}
						}
					}
				}
			}
		}

		.active {
			color: #d83e26 !important;
		}
	}

	.sort_dialog {
		width: 100%;
		height: 85vh;
		box-sizing: border-box;
		padding: 10px;
		position: relative;

		.title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
		}

		.my_sort {
			width: 100%;
			min-height: 50px;
			padding: 10px 0;
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid #d6d6d6;

			.no_my_sort {
				width: 100%;
				height: 50px;
				line-height: 50px;
				text-align: center;
			}

			.my_sort_item {
				width: calc(16% - 16px);
				height: 40px;
				padding: 0 6px 0 10px;
				background: #f0f0f0;
				margin-bottom: 10px;
				margin-right: 4px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.all_sort {
			width: 100%;

			.all_sort_title {
				padding: 8px 0;
				color: #b30d0f;
				font-size: 14px;
				font-weight: 700;
				text-align: left;
			}

			.all_sort_list {
				width: 100%;
				display: flex;
				flex-wrap: wrap;

				.all_sort_list_item {
					width: 16%;
					height: 40px;
					background: #f0f0f0;
					margin-bottom: 10px;
					margin-right: 4px;
					line-height: 40px;
					text-align: center;
					cursor: pointer;
				}
			}
		}

		.sort_btn {
			width: calc(100% - 20px);
			height: 44px;
			padding: 15px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			bottom: 0;
			left: 10px;

			.btn {
				width: 45%;
				height: 44px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 15px;
				background: #c1c1c1;
				color: #fff;
				cursor: pointer;
			}

			.determine {
				background: linear-gradient(#ff4536, #dd3b2e);
			}
		}
	}
</style>
