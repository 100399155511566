<!--
 * @Author: 云逸 1832583130@qq.com
 * @Date: 2023-10-18 16:32:05
 * @LastEditors: 云逸
 * @LastEditTime: 2023-10-18 17:51:30
 * @Description: 
-->
<template>
	<div class="optional">
		<div class="optional_left">
			<div
				class="item"
				:class="current == i ? 'item-active' : ''"
				v-for="(item, i) in offerList"
				:key="item.Name"
				@click="changeCurrent(i)"
			>
				{{ item.Name }}
			</div>
		</div>

		<div class="optional_right">
			<div class="item-box">
				<div class="item">名称</div>
				<div class="item" style="font-size: 14px; justify-content: flex-end">
					价格
				</div>
			</div>

			<el-checkbox-group v-model="checkedOffer" @change="checkedChange">
				<div class="item-box" v-for="item in foodsList" :key="item.Id">
					<div class="item">
						<el-checkbox :label="item.Title">{{ item.Title }}</el-checkbox>
					</div>

					<div class="item" style="font-size: 14px; justify-content: flex-end">
						{{ item.value }}
					</div>
				</div>
			</el-checkbox-group>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				current: 0,

				offerList: [],
				checkedNameList: [],

				checkedOffer: []
			}
		},

		computed: {
			foodsList() {
				if (this.offerList.length > 0) {
					return this.offerList[this.current].foods
				} else {
					return []
				}
			}
		},

		methods: {
			open() {
				return new Promise((resolve) => {
					const loading = this.$loading({
						lock: true,
						text: "Loading",
						spinner: "el-icon-loading",
						background: "rgba(0, 0, 0, 0.6)"
					})

					Promise.allSettled([this.getCheckedName(), this.getList()]).then(
						(res) => {
							console.log(res)
							loading.close()

							if (
								res[0].status == "fulfilled" &&
								res[1].status == "fulfilled"
							) {
								resolve(1)
							} else {
								resolve(0)
							}
						}
					)
				})
			},
			close() {
				this.checkedOffer = []
			},
			// 获取已自选
			async getCheckedName() {
				try {
					const { data: res } = await this.$api.offer.getMainList({
						date: this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}"),
						type: 2
					})
					console.log("自选", res)

					const checkedNames = []
					for (const i of res.list) {
						checkedNames.push(i.Title)
					}
					this.checkedOffer = checkedNames

					return Promise.resolve(1)
				} catch (e) {
					this.checkedOffer = []
					return Promise.reject(0)
				}
			},
			// 获取报价列表
			async getList() {
				try {
					const { data: res } = await this.$api.index.getList({
						date: this.$globalFun.parseTime(new Date(), "{y}-{m}-{d}"),
						category1: "全部",
						pageindex: 1,
						type: 0
					})
					// console.log(res)

					const list = res.list
					list.forEach((list) => {
						list.foods.forEach((item) => {
							item.value =
								item.OfferValue ||
								item.ZSJValue ||
								item.SZValue ||
								item.SZValue2 ||
								0
						})
					})

					this.offerList = list.filter((item) => {
						return item.foods.length
					})

					if (this.offerList.length === 0) {
						return Promise.reject(0)
					} else {
						return Promise.resolve(1)
					}
				} catch (error) {
					console.log(error)
					return Promise.reject(0)
				}
			},

			changeCurrent(i) {
				if (this.current == i) return

				this.current = i
			},

			checkedChange(e) {
				console.log(e)
			},

			determine() {
				console.log(this.checkedOffer)
				return new Promise((resolve) => {
					const title = this.checkedOffer.join("|")
					this.$api.offer
						.collectionSmoke({
							title
						})
						.then(() => {
							resolve(true)
						})
						.catch(() => {
							resolve(false)
						})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.optional {
		height: calc(100% - 74px);

		display: flex;
		justify-content: space-between;

		.optional_left {
			width: 150px;
			height: 100%;

			overflow-y: auto;

			background-color: #f3f3f3;

			.item {
				height: 50px;
				line-height: 50px;

				cursor: pointer;
			}

			.item-active {
				background-color: #dd3b2e;
				color: #fff;
			}
		}

		.optional_right {
			margin-left: 10px;
			padding: 10px 20px;

			box-sizing: border-box;

			flex: 1;
			height: 100%;

			overflow-y: auto;

			background-color: #f3f3f3;

			.item-box {
				width: 100%;
				height: 34px;

				display: flex;
				align-items: center;
				justify-content: space-between;

				.item {
					width: 100%;
					height: 34px;

					display: flex;
					align-items: center;
					justify-content: flex-start;

					cursor: pointer;
				}
			}
		}
	}
</style>
